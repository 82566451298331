<template>
    <v-row justify="center" align="center" class="login-container">
        <v-col cols="10" md="4">
            <v-card>
                <v-card-title>
                    Login
                </v-card-title>
                <v-card-text>
                    <v-text-field id="username-input" outlined label="Gebruikersnaam" v-model="username">
                    </v-text-field>
                    <v-text-field id="password-input" type="password" outlined label="Wachtwoord" v-model="password">
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn id="login-button" block color="primary" @click="login">
                        Login
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from '../../axios';
import Store from '../../store';

export default {
    data() {
        return {
            username: '',
            password: ''
        };
    },
    methods: {
        async login() {
            if (!(this.username && this.password)) {
                alert('Vul alle gegevens in');
                return;
            }
            try {
                const { data } = await axios.post('/admin/login', { username: this.username, password: this.password });

                axios.defaults.headers.authorization = data.token;
                localStorage.setItem('token', data.token);

                Store.set('admin', data.isAdmin);
                Store.set('username', data.username);

                this.$emit('login');

                this.$router.replace('/taxaties/lijst');
            } catch (error) {
                console.error(error);
                alert('Verkeerde gegevens ingevuld');
            }
        }
    },
    metaInfo: {
        title: 'Login'
    }
};
</script>

<style scoped>
.login-container {
    height: 100vh;
}
@media only screen and (max-width: 600px) {
    .login-container {
        height: 80vh;
    }
}
</style>
